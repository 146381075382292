import React, { FC } from 'react'
import * as StyledProductwishListCard from './productWishListCard.styles'
import { StorefrontProductList } from '@local-types/productLists/productList'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import IconHeart from 'icons/heart.svg'
import { getCroppedProdImg } from 'helpers/productHelpers/productHelpers'
import Link from 'next/link'

interface Props {
	list: StorefrontProductList
}

const ProductWishListsCard: FC<Props> = ({ list }) => {
	const listProducts = list.products
	const wishList: StorefrontProduct[] = listProducts.length > 6 ? listProducts.slice(0, 5) : listProducts
	const truncateWishlist: StorefrontProduct[] =
		listProducts.length > 6 ? listProducts.slice(5, listProducts.length) : []

	return (
		<Link href={`/listar/[${list.id}]`} as={`/listar/${list.id}`}>
			<StyledProductwishListCard.Card>
				<StyledProductwishListCard.ProductsWrapper>
					{wishList && wishList.length > 0 ? (
						<>
							{wishList.map((product: StorefrontProduct, index: number) => {
								return (
									<StyledProductwishListCard.ProductCard key={index}>
										<StyledProductwishListCard.ProductImage
											src={getCroppedProdImg(
												product.images[0]?.url ||
													'https://ss.datadwell.com/clients/ss/product_id/OP9019/600/500/0/image.png',
												180,
												180
											)}
											alt={product.images[0]?.altText || product.name}
										/>
									</StyledProductwishListCard.ProductCard>
								)
							})}
							{truncateWishlist.length > 1 && (
								<StyledProductwishListCard.ProductCard>
									<StyledProductwishListCard.ProductImage
										src={getCroppedProdImg(
											truncateWishlist[0].images[0]?.url ||
												'https://ss.datadwell.com/clients/ss/product_id/OP9019/600/500/0/image.png',
											180,
											180
										)}
										alt={truncateWishlist[0].images[0]?.altText || truncateWishlist[0].name}
									/>
									<StyledProductwishListCard.Overlay>
										<StyledProductwishListCard.Truncated>{`+${truncateWishlist.length}`}</StyledProductwishListCard.Truncated>
									</StyledProductwishListCard.Overlay>
								</StyledProductwishListCard.ProductCard>
							)}
						</>
					) : (
						<StyledProductwishListCard.Placeholder>
							<StyledProductwishListCard.PlaceholderIcon>
								<IconHeart />
							</StyledProductwishListCard.PlaceholderIcon>
							<StyledProductwishListCard.PlaceholderText>Engar vörur í lista</StyledProductwishListCard.PlaceholderText>
						</StyledProductwishListCard.Placeholder>
					)}
				</StyledProductwishListCard.ProductsWrapper>
				<StyledProductwishListCard.ListTitle>{list.name}</StyledProductwishListCard.ListTitle>
			</StyledProductwishListCard.Card>
		</Link>
	)
}

export default ProductWishListsCard
