import * as StyledCarousel from './productCarousel.styles'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { FC } from 'react'
import { IconButton, IconButtonLabel } from 'components/base/buttons/buttons'

interface ArrowProps {
	onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
}

const PrevArrow: FC<ArrowProps> = props => {
	const { onClick } = props

	return (
		<StyledCarousel.ArrowWrapper onClick={onClick} pos="left">
			<IconButton>
				<IconButtonLabel>Fyrri síða</IconButtonLabel>
				<FaChevronLeft />
			</IconButton>
		</StyledCarousel.ArrowWrapper>
	)
}

const NextArrow: FC<ArrowProps> = props => {
	const { onClick } = props

	return (
		<StyledCarousel.ArrowWrapper onClick={onClick} pos="right">
			<IconButton>
				<IconButtonLabel>Næsta síða</IconButtonLabel>
				<FaChevronRight />
			</IconButton>
		</StyledCarousel.ArrowWrapper>
	)
}

export const getSliderSettings = (isClient: boolean) => ({
	dots: false,
	arrows: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	prevArrow: <PrevArrow />,
	nextArrow: <NextArrow />,
	autoplay: false,
	draggable: false,
	responsive: isClient
		? [
				{
					breakpoint: 1439,
					settings: {
						slidesToShow: 4,
					},
				},
				{
					breakpoint: 1230,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 1,
						arrows: false,
					},
				},
		  ]
		: undefined,
})

export const getWishListSettings = (isClient: boolean) => ({
	...getSliderSettings(isClient),
	slidesToShow: 6,
	responsive: isClient
		? [
				{
					breakpoint: 1575,
					settings: {
						slidesToShow: 5,
					},
				},
				{
					breakpoint: 1439,
					settings: {
						slidesToShow: 4,
					},
				},
				{
					breakpoint: 1230,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 1,
						arrows: false,
					},
				},
		  ]
		: undefined,
})
