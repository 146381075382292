import styled from 'styled-components'
import { H5 } from 'components/base/typography/headings'

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;
	background-color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	transition: box-shadow 200ms;

	&:hover {
		box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.07);
	}
`
export const SingleListItem = styled.div``
export const ProductsWrapper = styled.div`
	position: abslolute;
	display: grid;
	grid-gap: 1.1rem;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, 80px);
`
export const ProductCard = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.grayLightest};
`
export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.boxOverlay};
	display: flex;
	align-items: center;
	justify-content: center;
`
export const Truncated = styled.span`
	font-weight: 500;
	font-size: 2.6rem;
	color: ${({ theme }) => theme.colors.white};
`
export const ProductImage = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	max-height: 100%;
`
export const ListTitle = styled(H5)`
	margin-top: 2.5rem;
	text-align: center;
`
export const Placeholder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`

export const PlaceholderIcon = styled.span`
	svg {
		transform: scale(1.2);
		path {
			fill: ${({ theme }) => theme.colors.grayLightest};
		}
	}
`

export const PlaceholderText = styled(H5)`
	color: ${({ theme }) => theme.colors.grayLightest};
`
