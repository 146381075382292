import React, { FC, useEffect, useState } from 'react'
import Slider from 'react-slick'
import * as StyledCarousel from './productCarousel.styles'
import { getSliderSettings, getWishListSettings } from './sliderSettings'
import ProductBox from '../productBoxes/productBox'
import ProductWishListCard from 'components/products/productWishList/productWishListCard'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import { StorefrontProductList } from '@local-types/productLists/productList'
import ProductBoxLoader from '../productBoxes/productBox/productBoxLoader'
import Link from 'next/link'

import { PageContainer } from 'components/base/grid/grid'

interface Props {
	paddingTop?: string
	paddingBottom?: string
	products?: StorefrontProduct[]
	productList?: StorefrontProductList[]
	title: string
	loading?: boolean
	url?: string
	isWishListProduct?: boolean
	backgroundImage?: string
}

const ProductCarousel: FC<Props> = ({
	products,
	productList,
	title,
	loading,
	paddingTop,
	paddingBottom,
	url,
	backgroundImage,
}) => {
	const [isClient, setIsClient] = useState(false)
	useEffect(() => setIsClient(true), [])
	const settings = products ? getSliderSettings(isClient) : getWishListSettings(isClient)
	const productsArray: (StorefrontProduct | StorefrontProductList)[] | undefined = products ?? productList

	return (
		<StyledCarousel.Wrapper backgroundImage={backgroundImage} paddingTop={paddingTop} paddingBottom={paddingBottom}>
			<PageContainer>
				{products && (
					<StyledCarousel.TitleWrapper>
						<StyledCarousel.Title>
							{url ? (
								<Link href={`/flokkur/[cslug]`} as={url}>
									<StyledCarousel.TitleAnchor href={url}>{title}</StyledCarousel.TitleAnchor>
								</Link>
							) : (
								title
							)}
						</StyledCarousel.Title>
					</StyledCarousel.TitleWrapper>
				)}
				{productList && (
					<StyledCarousel.TitleWrapper>
						<StyledCarousel.Title>
							{url ? (
								<Link href={`/minar-sidur/listar/`} as={url}>
									<StyledCarousel.TitleAnchor href={url}>{title}</StyledCarousel.TitleAnchor>
								</Link>
							) : (
								title
							)}
						</StyledCarousel.Title>
					</StyledCarousel.TitleWrapper>
				)}
				<StyledCarousel.SliderWrapper>
					<Slider {...settings} key={isClient ? 'client' : 'server'}>
						{products
							? products.map(product => (
									<StyledCarousel.BoxWrapper key={product.id}>
										{<ProductBox product={product} />}
									</StyledCarousel.BoxWrapper>
							  ))
							: productList &&
							  productList.map(product => (
									<StyledCarousel.BoxWrapper key={product.id}>
										<ProductWishListCard list={product} />
									</StyledCarousel.BoxWrapper>
							  ))}
						{/* react-slick workaround for handling fewer slides than indicated in the slider settings */}
						{!loading &&
							productsArray &&
							productsArray?.length < settings.slidesToShow &&
							[...Array(settings.slidesToShow - productsArray.length)].map((key, index) => <div key={index} />)}
						{loading &&
							[...Array(6)].map((_key, index) => (
								<StyledCarousel.BoxWrapper key={index}>
									<ProductBoxLoader />
								</StyledCarousel.BoxWrapper>
							))}
					</Slider>
				</StyledCarousel.SliderWrapper>
			</PageContainer>
		</StyledCarousel.Wrapper>
	)
}

export default ProductCarousel
