import Link from 'next/link'
import styled, { css } from 'styled-components'
import { H2 } from '../../base/typography/headings'

interface WrapperProps {
	backgroundImage?: string
	paddingTop?: string
	paddingBottom?: string
}
export const Wrapper = styled.div<WrapperProps>`
	${({ backgroundImage }) =>
		backgroundImage &&
		css`
			background-color: ${({ theme }) => theme.colors.grayLightest};
			background-image: url(${() => backgroundImage});
		`};
	padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '8.5rem')};
	padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '8.5rem')};
	height: 100%;
`

export const TitleWrapper = styled.div`
	margin-bottom: 4rem;
`

export const Title = styled(H2)``

export const SliderWrapper = styled.div`
	margin: 0, -1.5rem;

	.slick-list {
		margin: -5rem 0;
	}

	.slick-track {
		display: flex !important;
		margin: 5rem 0;
	}

	.slick-slide {
		height: auto;

		> div {
			position: relative;
			height: 100%;
		}
	}
`

export const BoxWrapper = styled.div`
	height: 100%;
	padding: 0 1.5rem;
`

interface ArrowWrapperProps {
	pos: 'left' | 'right'
}

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);

	${({ pos }) =>
		pos === 'left' &&
		css`
			left: -6rem;
		`}

	${({ pos }) =>
		pos === 'right' &&
		css`
			right: -6rem;
		`}
`

export const TitleAnchor = styled.a`
	color: ${({ theme }) => theme.colors.textDark};
`
